body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ak-main-bg{
  background-color: hsl(222.22deg 47.37% 11.18%);

  /* background: linear-gradient(115deg, #0f172a, #ffffff); */
  /* background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(218,220,226,1) 38%, rgba(57,63,81,1) 43%, rgba(15,23,42,1) 97%); */

}


@tailwind base;
@tailwind components;
@tailwind utilities;