.ak-exp-img-src{
    max-width: 148px !important;
    max-height: 170px !important;
}
.ak-exp-img-src-edu{
    max-width: 85px  !important;
    max-height: 140px !important;
}

.ak-exp-desc{
    color: #000000cc;
}

.ak-exp-border{
    border-radius: 28px !important;
}
