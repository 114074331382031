.ak-medium-card-image{
    height: 150px !important;
    border-radius: 28px 28px 0 0;
}


@media screen and (max-width: 575px) {
    .ak-medium-card-image{
        height: 150px !important;
        border-radius: 28px 28px 0 0;
    }
  }

  @media screen and (max-width: 768px) {
    .ak-medium-card-image{
        height: 150px !important;
        border-radius: 28px 28px 0 0;
    }
  }

  @media screen and (min-width: 1200px) {
    .ak-medium-card-image{
        height: 220px !important;
        border-radius: 28px 28px 0 0;
    }
  }


.ak-medium-card-body-bg{
  background-color: #131d35;
  border-radius: 0 0 28px 28px;
}

.ak-medium-card-link{
  color: #c9ccd2a3 !important;
}