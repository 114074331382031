.ak-badege-card-border{

    border-radius: 28px !important;
    padding: 0!important;
    margin: 0!important;

}

.ak-badege-card-size{

    max-width: 16rem !important;
    max-width: 19rem !important;
}



@media screen and (max-width: 768px) {
    .ak-badege-card-size{
        max-width: 10rem !important;
    }
  }