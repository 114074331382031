

.ak-cards{
    border-radius: 0 !important;
    padding: 0 !important;
    /* background-color: #ffffff; */
    background-color: #ffffff;
    /* background-size: cover !important; */
    width: 100% !important;
    height: 100% !important;

}

/* .ak-cards:hover{
    border-radius: 0 !important;
    border-style: double !important;
    border-width: thick !important;
    border-color: red !important;

} */

.ak-card-padding{
    padding: 0 !important;
    border-radius: 0 !important;
}

.showme {
    display: none;
}
  
.showhim:hover .showme {
    display: block;
}


.ak-card-border {
    --c: #ffa269; /* the border color */
    --b: 3px;    /* the border thickness*/
    --g: 5px;     /* the gap on hover */
    
    padding: calc(var(--g) + var(--b));
    --_g: #0000 25%,var(--c) 0;
    background: 
      conic-gradient(from 180deg at top    var(--b) right var(--b),var(--_g))
       var(--_i,200%) 0  /200% var(--_i,var(--b))  no-repeat,
      conic-gradient(            at bottom var(--b) left  var(--b),var(--_g))
       0   var(--_i,200%)/var(--_i,var(--b)) 200%  no-repeat;
    transition: .3s, background-position .3s .3s;
    cursor: pointer;
  }

  .showhim:hover .ak-card-border {
    --_i: 100%;
    transition: .3s, background-size .3s .3s;

}

.ak-card-v-cernter{
    display: flex;
    align-items:center;
    justify-content: center;
    /* background-color: #ffffff99; */
    background-color: #0f172a66;
    backdrop-filter: blur(2px);
}

.ak-card-no-border{
    border: 0 !important;
    background-color: #ffffff;
}


.ak-skill-card-bg{
    background-color: #0f172aeb !important;
    border-radius: 28px !important;
    border-color: #194732!important;
}