.ak-home{
  height: 85vh;

}


@media screen and (max-width: 768px) {
  .ak-home{
    /* height: 80vh; */
    height: 55vh;
  }
  
}

.ak-about{
  height: 100vh;
}
.ak-about-height{
  min-height: 75vh;
}

.ak-about-bg{ 
  background-color: #ffa269;
  background: linear-gradient(90deg, rgba(238,158,109,1) 0%, rgba(255,162,105,1) 100%);

}

.dummy-h{
  height: 100vh;
  width: 100vw;
}


.ak-home-btn{
  color: #ffa269 !important;
  border-color: #ffa269 !important;
  border-radius: 12px !important;
  width: 150px;
} 

.ak-home-btn:hover{
  color:black !important;
}

.ak-about-b1{
  border-radius: 28px 0 0 28px;
}
.ak-about-b2{
  border-radius: 0 28px 28px 0;

}


@media screen and (max-width: 575px) {
  .ak-about-b2{
    border-radius: 0 0 28px 28px;
  }
  .ak-about-b1{
    border-radius: 28px 28px 0 0;
  }
}


.ak-skill-border{
  border-radius: 28px ;
  background-color: #fff;
}

.ak-skill-border-badge{
  border-radius: 28px ;
}

.ak-footer-border{
  background-color:#0f172abf;
  backdrop-filter: blur(2px);
  /* filter: blur(8px); */
  border: 1px solid;
  border-color: #2f3133bf;
  border-bottom-color: #2b2f2c;
  box-sizing: border-box;
  border: 1 solid #e5e7eb;
  background-color: #0f172a14;
}

.ak-connect-bg{
  background-color: #263c6f26;
}

#more {display: none;}

.ak-btn-nav{
  /* color: #ffa269 !important; */
  border-color: #ffffff !important;
  background-color: #ffa269 !important;
  border-radius: 12px !important;
} 


.ak-skill-bg{

  background-color: #263c6f26 ;
}

.ak-skill-toggle-clr{
  border-color: #ffa269 !important;
  /* background-color: #15901d !important; */
}


.ak-skill-toggle-clr:checked{
  border-color: #ebebeb !important;
  background-color: #ffa269 !important;
}

.ak-skill-list-boder{
  border-radius: 16px !important;
  border-color: #131d35 !important;
}

.ak-skill-label-width{
  min-width: 85px !important;
}

.ak-skill-toggle-div{
  min-height: 30px !important;
}

.ak-skill-list-more{
  color: #ffa269;
}

.ak-skill-list-more:hover{
  color: #ffffff;
  outline: 2px solid #ffa269;; 
  border-radius: 10px; 
}


/* Flashing Button */

.flash_btn {
  border: 1px transparent;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  color: #fff7f7;
  cursor: pointer;
  display: inline-block;
  -webkit-animation: glowing 1300ms infinite;
  -moz-animation: glowing 1300ms infinite;
  -o-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite;
}
@-webkit-keyframes glowing {
  0% {
    background-color: #ffa269;
    -webkit-box-shadow: 0 0 3px #ffa269;
  }
  50% {
    background-color: #f9ae80;
    -webkit-box-shadow: 0 0 15px #f9ae80;
  }
  100% {
    background-color: #ffa269;
    -webkit-box-shadow: 0 0 3px #ffa269;
  }
}
@keyframes glowing {
  0% {
    background-color: #ffa269;
    box-shadow: 0 0 3px #ffa269;
  }
  50% {
    background-color: #f9ae80;
    box-shadow: 0 0 15px #f9ae80;
  }
  100% {
    background-color: #ffa269;
    box-shadow: 0 0 3px #ffa269;
  }
}

/* Flashing Button */


.ak-about-hover-text-dark:hover{
  color: #131d35 !important;
}

.ak-home-name{
  color: #ffa269 !important;
}