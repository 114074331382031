.ak-navbar{
    background-color:#0f172abf;
    backdrop-filter: blur(2px);
    /* filter: blur(8px); */
    border: 1px solid;
    border-color: #2f3133bf;
    border-bottom-color: #2b2f2c;
    box-sizing: border-box;
    border: 1 solid #e5e7eb;

  }

.ak-btn-nav{
  /* color: #ffa269 !important; */
  border-color: #ffffff !important;
  background-color: #ffa269 !important;
  border-radius: 12px !important;
} 


.ak-nav-resume-btn:hover{
  background-color: #e5e7eb00 !important;
  border: 1 solid #ffa269 !important;
  border-radius: 12px !important;
  border-color: #ffa269 !important;
  color: white !important;
}


.ak-nav-item:hover{
  color: #ffa269 !important;
}

.ak-nav-item:active{
  color: #fd7420 !important;
}
