.mj{
    width: 60px;
}

.ak-social-border{
    border-radius: 28px !important;
}

.ak-social-btn-border{
    border-radius: 12px !important;
    border-color: #ffa269 !important; 
    
}
.ak-social-card-docker-icon{
    height: 20px;
    width: 17px;
}


.ak-skill-list-boder{
    border-radius: 16px !important;
    border: none !important;
}

.ak-skill-list-bg{
    background-color: #0f172aeb!important; 
    border-color: #0f172a !important;
}